<template>
  <div class="form-stepper form-stepper-campaign">
    <Loading :value="loading"/>
    <v-form
      ref="form"
      v-model="valid"
      class="form pa-6"
    >
      <div class="d-flex pb-4 align-center">
        <p class="text-h6 mb-0"><span>{{ $t('CAMPAIGN.STEP_INTRO_TITLE') }}</span></p>
        <v-btn
          x-small
          depressed
          rounded
          color="primary"
          class="ml-4"
          @click="$emit('step', 0)"
        >
          {{ $t('COMMON.MODIFY') }}
        </v-btn>
      </div>
      <div>
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
            {{ $t('INPUT.TITLE') }}
          </v-col>
          <v-col cols="6" class="text-ellipsis text-nowrap">
            <span>{{ values.title }}</span>
          </v-col>
        </v-row>

        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
            {{ $t('CHANNEL.TITLES') }}
          </v-col>
          <v-col cols="8">
            <p>{{ $tc('CHANNEL.TITLE_COUNT', channelSubscriptionItems.length) }}</p>
            <div class="d-flex flex-wrap">
              <template v-for="(item, i) in channelSubscriptionItems">
                <div
                  :key="'ch' + i"
                  class="thumb-channel"
                >
                  <v-img width="50px" max-height="15px" contain :src="getImage(item)" />
                  <div class="thumb-channel-category">{{item.channel.category}}</div>
                </div>
              </template>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="d-flex pb-4 align-center mt-4">
        <p class="text-h6 mb-0"><span>{{ $t('CAMPAIGN.STEP_DATES_TITLE') }}</span></p>
        <v-btn
          x-small
          depressed
          rounded
          color="primary"
          class="ml-4"
          @click="$emit('step', 1)"
        >
          {{ $t('COMMON.MODIFY') }}
        </v-btn>
      </div>
      <div>
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
            {{ $t('CAMPAIGN.START_DATE') }}
          </v-col>
          <v-col cols="6" class="text-ellipsis text-nowrap">
            <span class="number">{{ new Date(values.start_date).toLocaleDateString() }}</span> <span class="grey--text ml-2">{{$moment(values.start_date).fromNow()}}</span>
          </v-col>
        </v-row>

        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
            {{ $t('CAMPAIGN.END_DATE') }}
          </v-col>
          <v-col cols="6" class="text-ellipsis text-nowrap">
            <span class="number">{{ new Date(values.end_date).toLocaleDateString() }}</span> <span class="grey--text ml-2">{{$moment(values.end_date).fromNow()}}</span>
          </v-col>
        </v-row>
      </div>

      <div class="d-flex pb-4 align-center mt-4">
        <p class="text-h6 mb-0"><span>{{ $t('CAMPAIGN.STEP_STATS_TITLE') }}</span></p>
        <v-btn
          x-small
          depressed
          rounded
          color="primary"
          class="ml-4"
          @click="$emit('step', 2)"
        >
          {{ $t('COMMON.MODIFY') }}
        </v-btn>
      </div>
      <div v-if="values.ga4_property_id && values.ga4_conversion_event_name">
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
            {{ $t('CAMPAIGN.FIELD_GA4_PARAMS_PROPERTY_ID') }}
          </v-col>
          <v-col cols="6" class="text-ellipsis text-nowrap">
            <span class="number">{{values.ga4_property_id}}</span>
          </v-col>
        </v-row>

        <v-divider />
        <v-row class="mt-0 mb-0 text-medium">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
            {{ $t('CAMPAIGN.FIELD_GA4_PARAMS_CONVERSION_EVENT_NAME') }}
          </v-col>
          <v-col cols="6" class="text-ellipsis text-nowrap">
            <span class="number">{{values.ga4_conversion_event_name}}</span>
          </v-col>
        </v-row>
      </div>

    </v-form>
    <v-divider/>

    <v-toolbar
      color="transparent"
      flat
      height="70px"
      class="form-footer"
    >
      <v-spacer/>

      <v-btn
        color="success"
        class="mr-4"
        rounded
        depressed
        :disabled="!valid && !loading"
        large
        @click="validate"
      >
        <v-icon class="mr-2">mdi-check</v-icon>
        <strong>{{ $t('COMMON.SAVE') }}</strong>
      </v-btn>
    </v-toolbar>

  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'

export default {
  name: "FormCampaignStepperConfirm",
  components: {
    Loading,
  },
  props: {
    values: {
      type: Object,
      default: () => ({}),
    }
  },
  created () {
    this.loadChannelSubscriptionItems()
  },
  data: () => ({
    loading: false,
    valid: true,
    channelSubscriptionItems: [],
  }),
  methods: {

    getImage(item, size = 'original') {
      const url =
        `${process.env.VUE_APP_ASSETS}/channels/`
      return `${url + item.channel.controller_name.toLowerCase()}.png`
    },

    loadChannelSubscriptionItems () {
      this.loading = true
      let defaultWhere = {
        status: [1],
        channel: {
          required: true,
        },
      };

      let where = {};

       if (this.values.methods === "organic") {
        where.methods = "organic"
      } else {
        where.channel = {
          required: true,
          category : this.values.channel_categories
        }
      }

        // {where: {status: [1], methods: this.values.methods, types: this.values.types, channel: true, }}

      this.$services.api_programmatic.channel_subscription.search({where: Object.assign({}, defaultWhere, where)}
        )
        .then(response => {
          if (response.data) {
            this.channelSubscriptionItems = response.data
          } else {
            this.channelSubscriptionItems = []
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    async validate() {
      if (!this.$refs.form.validate()) return
      const values = this.$clone(this.values)
      this.loading = true
      try {
        const response = await this.$services.api_programmatic.campaign.create({values})
        this.$emit('validation', true)
        this.$emit('create')
        this.$emit('close')
      }
      catch(e) {
        this.$emit('validation', false)
        await this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
      }
      finally {
        this.loading = false
      }
    },
  }
}
</script>

<style scoped>

</style>
